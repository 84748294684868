.sectionTitle{
  text-align: center;
  padding: 5em 0 5em 0;
  @media (max-width: 425px) {
    padding: 5em 0 4em 0;
  }
  &__line{
    font-size: 1.8em;
    font-family: $fontTitle;
    font-weight: 600;
    color: $colorbgFontGrey;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 2px solid $orangeDark;
    padding-bottom: 0.6em;
    @media (max-width: 768px){
      font-size: 1.4em;
    }
  }
  &__discription{
    font-size: 1em;
    font-weight: 400;
    padding-top: 2.5em;
    letter-spacing: 0.2em;
    color: $colordGrey;
    @media (max-width: 768px){
      font-size: 0.9em;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}

