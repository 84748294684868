.site-title {
    height: 650px;
    width: 100%;
    background: none;
    padding-top: 230px;

    @media (max-width: 768px) {
        height: 450px;
        padding-top: 100px;
    }
}

.site-title__card {}

.site-title__animationWrapper {
    position: relative;
    right: 300px;

    @media (max-width: 768px) {
        right: 140px;
    }
}

.site-title__button {
    letter-spacing: 0.2em;
    font-family: Lato;
    font-weight: 600 !important;
    color: $black;
    border: 2px solid $black;
    border-radius: 0;
    @include bounce-to-right;
    margin-left: 12px;
    &:hover {
        color: $black;
      }
    
      &:active {
        color: $black;
      }
    
      &:focus {
        color: $black;
      }
    
      &--left {

    
        @media (min-width: 769px) {
          margin-top: 4em;
        }
    
        @media (max-width: 768px) {
          margin-top: 2em;
          margin-left: 1em;
        }
      }
}

.site-tile__text {
    color: $black;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 5em;
    line-height: 1.1;
    text-align: start;
    z-index: 2;
    @media (max-width: 428px) {
        font-size: 3.6em;
        margin-left: 0;
    }
}

.header-container {
    margin-right: -150px;
    margin-top: 200px;
    color: $black;
    @media (max-width: 412px) {
        margin-right: 0px;        
        // margin-left: 24px;
        marginTop: 150px;
    }
}

.no-top{
    padding-top: 0 !important;
}