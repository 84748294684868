body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.red{
  color: white !important;
  border-color: white !important;
  font-family: 'Lato', sans-serif;
  display: inline;
}

a {
  color: $orangeDark;
  text-decoration: none
}

a:hover {
  color: $orangeDark !important;
}

p {
  margin-top: 0;
  color: $colormGrey;
}

h1 {
  color: white;
  font-family: 'lato'sans-serif;
  line-height: 60px;
  font-weight: 600;
  z-index: 2;
  padding-top: 24px;

  @media (max-width: 320px) {
      font-size: 32px;
      line-height: 60px;
  }

  @media (min-width: 321px) {
      font-size: 40px;
      line-height: 60px;
  }

  @media (min-width: 768px) {
      font-size: 52px;
      line-height: 60px;
  }

  @media (min-width: 992px) {
      font-size: 75px;
      line-height: 60px;
  }
}

h2 {
  color: white;
  font-size: 54px;
  font-style: italic;
  font-family: 'Slabo 27px', serif;
  z-index: 2;

  @media (max-width: 768px) {
      font-size: 22px;
  }

}

::-webkit-input-placeholder {
  color: $colormGrey!important;
  opacity: 1 !important;
  /* for older chrome versions. may no longer apply. */
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $colormGrey!important;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $colormGrey !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: $colormGrey !important;
}