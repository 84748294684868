.footer__menu {
  padding: 5em 0;
  //font-family: $fontTitle;
  //text-transform: uppercase
  font-weight: 400;
  color: white;
  text-align: center;
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
}

.footer__copyright {
  letter-spacing: 0.1em;
}


.footer__share {
  display: inline-block;
  letter-spacing: 0.3em;

  @media (max-width: 768px) {
    padding-top: 0.9em;
  }
}

.icon__hover:hover .icon__hover:focus {
  transform: scale(1.5);
}


.footer__share--links {
  letter-spacing: 0.1em;
  padding-right: 0.5em;
}