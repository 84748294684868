a {
    color: $orangeDark;
    text-decoration: none
}

a:hover {
    color: $orangeDark !important;
}

p {
    margin-top: 0;
    color: $colormGrey;
}

h1 {
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    z-index: 2;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1em;
    letter-spacing: 2px;
    text-align: start;
    @media (max-width: 428px) {
        margin-left: 0px;
    }
}

h2 {
    color: black;
    
    @media (max-width: 768px) {
        font-size: 22px;
    }

}

::-webkit-input-placeholder {
    color: $colormGrey!important;
    opacity: 1 !important;
    /* for older chrome versions. may no longer apply. */
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $colormGrey!important;
    opacity: 1 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $colormGrey !important;
    opacity: 1 !important;
}

:-ms-input-placeholder {
    color: $colormGrey !important;
}