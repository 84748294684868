.timeline{
    background: white;
    padding-top: 5em;
    @media (max-width: 768px){
        padding-top: 0;
    }
}


.timeline__mainLine{
    position: absolute;
    //left: 0;
    right: 0;
    border-right: 2px solid #e6e6e6;
    width: 2px;
    z-index: 1;
    @media (max-width: 425px){
        //left: 10px;
        left: 50px;
        margin-left: 0;
        margin-right: 0;
        height: 860px;
    }
    @media (min-width: 426px) {
        //left: 10px;
        left: 160px;
        height: 740px;
    }
    @media (min-width: 992px) {
        //left: 10px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        height: 900px;
    }
}

.timeline__dot{
    z-index: 2;
    border-radius:100%;
    color: #fff;
    width:24px !important;
    height:24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    @media (max-width: 425px){
        margin-top: 31px;
        position: absolute;
        left: 42px;
        z-index: 2;
        width:18px !important;
        height:18px !important;
    }
    @media (min-width: 426px) and (max-width: 768px) {
        margin-top: 31px;
        position: absolute;
        left: 152px;
        z-index: 2;
        width:18px !important;
        height:18px !important;
        left: 152px;
        margin-left: 0;
        margin-right: 0;
        height: 740px;
    }
    @media (min-width: 769px) {
        // position: absolute;
        // //left: 10px;
        // left: 1px;
        height: 980px;
        width:24px !important;
        height:24px !important;
        margin-left: auto;
        margin-right: auto;
    }
}

.timeline__dot--finish{
    z-index: 2;
    border-radius:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    @media (max-width: 425px){
        margin-top: 31px;
        position: absolute;
        left: 42px;
        z-index: 2;
        width:22px !important;
        height:22px !important;
        border: 2px solid #e6e6e6;
    }
}




.timeline__line{
    border-right: 2px solid #e6e6e6;
    height: 150px;
    width: 100%;
}

.timeline__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    z-index: 2;
}

.timeline__title{
    //padding-top:1em;
    @extend .subtitle;
    line-height: 1.6em;
    &--right{
        @extend .timeline__title;
        text-align: right;  
    }
}

.timeline__text{
    text-align: right;
}

.timeline__pointer{
    border-bottom: 2px $orangeDark solid;
    width: 202px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right:-150px;
    &--right{
        @extend .timeline__l;
        margin-right: 0;
        margin-left:-150px;
        @media (max-width: 768px){
            width: 100px;
            margin-left:-30px;
        }
    }
    @media (max-width: 768px){
        width: 100px;
        margin-right:0;
    }
    @media (max-width: 768px){
        width: 100px;
        margin-right:0;
    }
  }

.timeline__lineHeight{
    height: 90px;   
    @media (max-width: 425px){
        height: 60px;   
    }
}



.timeline__cirle{
    border-radius:100%;
    color: #fff;
    border-radius: 100%;
    border: 4px solid #e6e6e6;
    line-height:24px;
    font-size:16px;
    width:140px !important;
    height:140px !important;
    font-weight:  500;
    display: flex;
    justify-content: center;
    align-items: center;
    //font-size:16px;
    font-family: $fontTitle;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    text-align: center;
    //background: white;
    &--text{
        @extend .timeline__cirle;
        // color: $orangeDark !important;
        font-size: 16px !important;
        // text-align: center;
    }
    @media (max-width: 425px){
        font-size: 12px !important;
        width:60px !important;
        height:60px !important;
    }
}



