/* Bounce To Bottom */
@mixin bounce-to-bottom {
	@include hacks();
	position: relative;
	@include prefixed(transition-property, color);
	@include prefixed(transition-duration, $slowDuration);

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $white;
		@include prefixed(transform, scaleY(0));
		@include prefixed(transform-origin, 50% 0);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-duration, $slowDuration);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {
		color: $colorContrast;

		&:before {
			@include prefixed(transform, scaleY(1));
			@include prefixed(transition-timing-function, cubic-bezier(.52,1.64,.37,.66));
		}
	}
}
