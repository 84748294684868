//main font
$fontMain: "Lato", sans-serif;

//title font

$fontTitle: "Montserrat", sans-serif;
//'Roboto', sans-serif

//alt fonts
$fontLogo: "Ailerons";

$font1: "Open Sans", sans-serif;
$font2: "Montserrat", sans-serif;
$font3: "Montserrat Light", sans-serif;
$font4: "Gentium", serif;
$font5: 'Libre Baskerville', serif;
$font6: 'Lora', serif;
//"Nunito", sans-serif;


//Project colors

$yellow: #FFB231;
$orangeLight:#EF7D31;
$orangeMedium:#E94E0E;
$orangeDark:#EF4D08;

$colorContrast: #cc3333;
$colorYellow:  #cc3333;
$colordGrey: #3b3b3b;
$colorbgGrey: #2c2c2c;
$campaignBgGrey: #151414;
$colorbgFontGrey: #383535;
$colormGrey: #666666;
$colorlGrey: #f1f1f1;

// Cool
$aqua:  #7FDBFF;
$blue:  #0074D9;
$navy:  #001F3F;
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;


// Warm
// $yellow:  #FFDC00;
// $orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;


// Gray Scale
$white:  #fff;
$silver: #ddd;
$gray:   #aaa;
$black:  #111;
