.up {
    margin-top: 5em;
}

.pullRight {
    float: right
}

.pullLeft {
    float: left
}

.bold {
    color: $orangeDark !important;
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}

.noStyle {
    color: inherit;
}

.noStyle:hover {
    color: $colormGrey !important;
}

.noStyle:focus {
    color: $colormGrey !important;
}

.email {
    text-transform: none;
    color: inherit;
}

.noStyleButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.bg__orangeMedium {
    background: #EF4D08;
}

.bg__orangeDark {
    background: #EF4D08;
}