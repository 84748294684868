.about-us{
    padding-top:1em;
    background: white;
    padding-bottom: 5em;
    @media (max-width: 768px){
        padding-bottom: 0;
    }
}
.about-us__row{
    margin-top: 3em;
    @media (max-width: 768px){
        margin-top: 0;
    }
}
.space{
    width: 100%;
    height: 200px;
    // background-image: url("./assets/img/test14.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 768px) {
        height: 80px;
    }
}

.slogan-container{
    padding-top: 62px;
    margin-bottom: 154px;
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 428px) {
        padding-top: 200px;
        margin-bottom: 250px;
        padding-top: 12px;
        padding-left: 24px;
    }
}
