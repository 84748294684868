.nav__logo{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.nav__list{
    list-style: none !important;
    display: flex;
    padding-inline-start: 0;
    flex-grow: 1;
    justify-content: flex-end;
}

.nav__list-item{
    text-transform: uppercase;
    display: inline-flex;
    margin-right: 12px;
    padding-top:3px;
    padding-bottom: 3px;
    @include underline-from-left;
}

.nav__list-item--en{
    @extend .nav__list-item;
    cursor: pointer; 
}

.nav__mobileMenu{
    top: 49px !important;
    font-family: $fontMain;
    font-size: 14px !important;
    color: $colormGrey;
}

.nav__mobileMenu--item{
    border-bottom: 1px solid $orangeDark !important;
}

.nav__list-item--underline{
    border-bottom: 1px solid $orangeDark; 
}

.nav__list--secondary{
    list-style: none !important;
    display: flex;
    padding-inline-start: 0;
    padding-left: 12px;
}

.nav__list-item--border{
    border-left-style: solid;
    border-left-color: $orangeDark;
    border-left-width: 1px;
    padding-left: 12px;
}

.nav__search-button{
margin-left: 12px !important;
}

.nav__selected{
    padding-left: 12px;
    border-left: solid 1px #EF4D08;
    color: #EF4D08;
    font-weight: 300 !important;
    display: inline-flex;

}
.nav__selected--cancel{
    padding-left: 12px;
    cursor: pointer;
}

.nav__hamburger{
    float: right;
}






