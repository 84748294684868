.buildings {
  background: white;
  padding-top: 5em;

  @media (max-width: 768px) {
    padding-top: 0;
  }
}

.buildings__seperator {
  border-bottom: 2px $orangeDark solid;
  width: 4em;
  padding-top: 2em;

  &--selected {
    @extend .buildings__seperator;
    padding-top: 4em;

    @media (max-width: 768px) {
      padding-top: 2em;
    }
  }
}

.buildings__cancel {
  float: right;
  margin-right: 18%;
  margin-top: 5.1em;

  &--top {
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  &--bottom {
    @media (max-width: 768px) {
      display: inline-block;
      margin-top: 2em;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.buildings__discription {
  padding-top: 1.5em;
  text-align: center;
  padding-bottom: 5em;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}

.bulidings__contact {
  line-height: 2em;
  padding-bottom: 1.5em;
}

.buildings__info {
  font-style: italic;
}


.buildings__nextInput {
  width: 100% !important;
  padding-top: 4em;

  @media (max-width: 768px) {
    padding-top: 2em;
  }
}

.buildings__card {
  @extend .card;
  padding: 2em 1.6em 4em 2em;
  border: $orangeDark 2px solid;
  outline: 0;

  .card__title {
    font-size: 1.2em;
    padding-right: 0;
  }
}