@import './styles/Semantic-UI/semantic.css';
@import './styles/var.scss';
@import './styles/buttons/_hover.scss';
@import './styles/layout.scss';
@import './styles/base.scss';
@import './styles/utils.scss';
@import './styles/modules.scss';
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";





