.subtitle{
  font-family: $fontTitle;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 0.2em;
  color: $colorbgFontGrey;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 1.1em;
  }
}

.subtitle__line{
  border-bottom: 2px $orangeDark solid;
  width: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}


