.photos{
    background-color: white;
    padding-top: 6em;
    @media (max-width: 768px){
      padding-top: 0;
  }
}

.photo__title{
    @extend .subtitle;
    padding-top:1em;
    padding-bottom:4px;
}

.photos__row{
    padding-bottom: 2em;
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.95;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: $orangeDark;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .closeN {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.95;
  }
  .closeN:hover {
    opacity: 1;
  }
  .closeN:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: $orangeDark;
  }
  .closeN:before {
    transform: rotate(45deg);
  }
  .closeN:after {
    transform: rotate(-45deg);
  }

  .image-gallery-thumbnail.active {
    border: 2px solid $orangeDark !important; 
}