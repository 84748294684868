.timeline{
    background: white;
    padding-top: 5em;
    @media (max-width: 768px){
        padding-top: 0;
    }
}

.timeline__cirle{
    border-radius:100%;
    color: #fff;
    border-radius: 100%;
    border: 4px solid #e6e6e6;
    line-height:24px;
    font-size:16px;
    width:140px;
    height:140px;
    font-weight:  500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontTitle;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    text-align: center;
    &--text{
        @extend .timeline__cirle;
        font-size: 16px !important;
    }
}

.timeline__dot{
    z-index: 2;
    border-radius:100%;
    color: #fff;
    width:24px !important;
    height:24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    &--text{
        @extend .timeline__cirle;
        font-size: 20px !important;
    }
}


.timeline__line{
    border-right: 2px solid #e6e6e6;
    height: 150px;
    width: 100%;
}

.timeline__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    z-index: 2;
}

.timeline__title{
    @extend .subtitle;
    line-height: 1.6em;
    &--right{
        @extend .timeline__title;
        text-align: right;  
    }
}

.timeline__text{
    text-align: right;
}

.timeline__l{
    border-bottom: 2px $orangeDark solid;
    width: 202px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right:-150px;
    &--right{
        @extend .timeline__l;
        margin-right: 0;
        margin-left:-150px;
    }
  }

