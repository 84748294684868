nav {
  background-color: $white;
  webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.42);
  moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $fontMain;
  font-size: 14px;
  color: $colormGrey;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 100;

  @media (max-width: 1400px) {
    padding: 9px 5% 9px 5%;
    justify-content: space-between;
  }

  @media (min-width: 1399px) {
    padding: 5px 16% 5px 16%;
  }
}

article {
  display: flex;
  justify-content: flex-end;
}

footer {
  background-color: $campaignBgGrey;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}