.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"]{
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus{
  color: #333;
  outline: 0;
}


.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary{
  color: #FFF;
  background-color: #33C3F0;
  border-color: #33C3F0;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus{
  color: #FFF;
  background-color: #1EAEDB;
  border-color: #1EAEDB;
}


.button__light{
  margin-left: 12px;
  margin-bottom: 5px;
  letter-spacing: 0.2em;
  font-family: Lato;
  font-weight: 400 !important;
  background-color: $orangeDark;
  color: white;
  border: 1px solid $orangeDark;
  border-radius: 0;
  @include bounce-to-right;
  &:hover{
    color: $orangeDark;
  }
}

.button__dark {
  letter-spacing: 0.2em;
  font-family: $fontTitle;
  font-weight: 600 !important;
  background-color: $colorbgFontGrey;
  color: white;
  border: 1px solid $colorbgFontGrey;
  border-radius: 0;
  @include bounce-to-right;
  @media (max-width: 425px) {
    width: 60%;
  }

  &:hover {
    color: $colorbgFontGrey;
  }

  &:active {
    color: $colorbgFontGrey;
  }

  &:focus {
    color: $colorbgFontGrey;
  }

  &--left {
    @extend .button__dark;  

    @media (min-width: 769px) {
      margin-top: 4em;
    }

    @media (max-width: 768px) {
      margin-top: 2em;
    }
  }
}
