.card{
    color: white;
    padding: 2.2em 4em;
    background-color: $orangeDark;
    webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
}

.card__title{
    padding-top: 0.2em;
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-family: $fontTitle;
    text-transform: uppercase;
}

.card__button{
    margin-top: 1em;
    display: inline-block;
    color: white;
    border-color: white;
    border-width: 1px;
    font-weight:  600;
    padding: 0 30px;
    border-radius: 0;
    font-family: $fontTitle;
    @include bounce-to-right;
}

.card__main{
    @extend .card;
    max-width: 470px;
    padding: 5em 4em !important;
    @media (max-width: 768px){
        margin-bottom: 2em;
    }
}

